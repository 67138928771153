import { Text } from '@atoms/index';
import { AnimatePresence, motion } from 'framer-motion';
import { opportunitiesFilterTypes } from '@helpers/enums/opportunities-filter-types';
import Button from '@ui/atoms/design-system/Button';
import { useRouter } from 'next/router';
import { useSearchParams } from 'next/navigation';
import { Image } from '@ui/atoms';
import { useEffect, useMemo, useState } from 'react';
import { opportunitiesGaEventsName } from '@helpers/ga-events-name-enum';
import { triggerCustomGaEvent } from '@helpers/miscelleanous';
import OpportunitiesOverviewHeader from '@components/Investor/Opportunities/OpportunitiesOverviewHeader';
import OpportunityTabs from '@components/Investor/Opportunities/Filters/OpportunityTabs';
import FilterAndSort from '@components/Investor/Opportunities/Filters/FilterAndSort';

const OpportunityOverview = ({
    showActiveOpportunities = true,
    activeButton,
    stats,
    isUnlistedSDI,
    setIsUnlistedSDI,
    showResultsScreen,
    count,
    ...property
}) => {
    const router = useRouter();
    const [descriptionText, setDescriptionText] = useState('');
    const searchParams = useSearchParams();
    const filter = searchParams.get('type') || 'bond';

    const oppportunitiesTabs = {
        BONDS: 'Bonds',
        SDIS: 'SDIs',
        TBILLS: 'T-Bills'
    };


    const sdiTabs = {
        RBI_REGULATED: 'RBI Regulated',
        SEBI_REGULATED: 'SEBI Regulated',
    };

    const tabs = [
        {
            id: opportunitiesFilterTypes.BOND,
            value: oppportunitiesTabs.BONDS,
            count: stats?.bonds || 0,
        },
        {
            id: opportunitiesFilterTypes.SDI,
            value: oppportunitiesTabs.SDIS,
            count: (stats?.sebiSDIs || 0) + (stats?.rbiSDIs || 0),
        },
        {
            id: opportunitiesFilterTypes.T_BILL,
            value: oppportunitiesTabs.TBILLS,
            count: stats?.tBills || 0,
        },
    ];

    const regulationTabs = useMemo(() => {
        const initialTabs = [
            {
                id: "rbi",
                value: sdiTabs.RBI_REGULATED,
                count: stats?.rbiSDIs || 0,
                onClick: () => {
                    setIsUnlistedSDI(true);
                    triggerGaEventsForsdiTabs(sdiTabs.RBI_REGULATED);
                },
            },
            {
                id: "sebi",
                value: sdiTabs.SEBI_REGULATED,
                count: stats?.sebiSDIs || 0,
                onClick: () => {
                    setIsUnlistedSDI(false);
                    triggerGaEventsForsdiTabs(sdiTabs.SEBI_REGULATED);
                },
            },
        ];
    
        return !stats?.rbiSDIs && stats?.sebiSDIs ? [initialTabs[1], initialTabs[0]] : initialTabs;
    }, [stats]); 

    useEffect(() => {
        switch (filter) {
            case opportunitiesFilterTypes.BOND: setDescriptionText('Corporate bonds are fixed-income generating instruments and the returns are not linked to the performance of the stock market. These are a form of debt security issued by governments, and public and private corporations to raise funds for various purposes.\n\nThese corporate bonds are listed and regulated by SEBI.');
                return;
            case opportunitiesFilterTypes.T_BILL: setDescriptionText('Considered among the best fixed-income investments in India, T-Bills are zero coupon securities offered at a discount to the face value of the security. The full security face value is paid on the due date.\n\nT-Bills are short-term debt instruments from the government with three tenures of 91, 182 or 364 days.');
                return;
            case opportunitiesFilterTypes.SDI: isUnlistedSDI
                ? setDescriptionText('SDIs are securities that represent an ownership interest in a pool of income generating assets, typically loan receivables, invoice receivables or real assets. The income generated from the underlying assets is distributed to the security holders.\n\nUnlisted SDIs are regulated by RBI.')
                : setDescriptionText('SDIs are securities that represent an ownership interest in a pool of income generating assets, typically loan receivables, invoice receivables or real assets. The income generated from the underlying assets is distributed to the security holders.\n\nListed SDIs are regulated by SEBI.');
                return;
            default: setDescriptionText('');
        }
    }, [filter, isUnlistedSDI]);

    const opacity = {
        hidden: {
            opacity: 0,
            zIndex: -100,
            x: -100,
        },
        visible: {
            opacity: 1,
            zIndex: 5,
            transition: {
                duration: 0.1,
                x: 0,
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.1,
            },
        },
    };

    const openOtherOpportunitiesPage = () => {
        if (showActiveOpportunities)
            router.push(`/opportunities/closed`);
        else
            router.push(`/opportunities`);
    }

    const ViewOtherOpportunitiesButton = ({ buttonType, buttonSize, className }) =>
        <Button
            buttonSize={buttonSize}
            buttonType={buttonType}
            buttonText={showActiveOpportunities ? 'View recently closed opportunities' : 'View active opportunities'}
            onClick={openOtherOpportunitiesPage}
            suffixComponent={<Image src="/images/arrow-right-2.svg" alt="arrow" />}
            className={className}
        />

    const triggerGaEventsForsdiTabs = (value) => {
        let eventName;
        if (showActiveOpportunities) {
            switch (value) {
                case sdiTabs.RBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_RBI_SDI_TAB_ACTIVE
                    break;
                case sdiTabs.SEBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_SEBI_SDI_TAB_ACTIVE
                    break;
                default:
                    break;
            }
        } else {
            switch (value) {
                case sdiTabs.RBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_RBI_SDI_TAB_RECENTLY_CLOSED
                    break;
                case sdiTabs.SEBI_REGULATED:
                    eventName = opportunitiesGaEventsName.CLICKED_SEBI_SDI_TAB_RECENTLY_CLOSED
                    break;
                default:
                    break;
            }
        }
        triggerCustomGaEvent(eventName);
    }

    const triggerGaEventsForOpportunitiesTabs = (value) => {
        let eventName;
        if (showActiveOpportunities) {
            switch (value) {
                case oppportunitiesTabs.BONDS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_BONDS_TAB_ACTIVE
                    break;
                case oppportunitiesTabs.SDIS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_SDI_TAB_ACTIVE
                    break;
                case oppportunitiesTabs.TBILLS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_TBILLS_TAB_ACTIVE
                    break;
                default:
                    break;
            }
        } else {
            switch (value) {
                case oppportunitiesTabs.BONDS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_BONDS_TAB_RECENTLY_CLOSED
                    break;
                case oppportunitiesTabs.SDIS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_SDI_TAB_RECENTLY_CLOSED
                    break;
                case oppportunitiesTabs.TBILLS:
                    eventName = opportunitiesGaEventsName.CLICKED_VIEW_TBILLS_TAB_RECENTLY_CLOSED
                    break;
                default:
                    break;
            }
        }
        triggerCustomGaEvent(eventName);
    }

    return (
        <AnimatePresence mode="wait">
            <section className="flex flex-col items-center justify-start w-full gap-4 md:items-start md:max-w-[710px] xl:max-w-[1096px]">
                <OpportunitiesOverviewHeader
                    title={
                        showActiveOpportunities
                            ? 'Active Opportunities'
                            : 'Recently Closed Opportunities'
                    }
                    description={
                        showActiveOpportunities
                            ? 'Invest in curated, secured, high-yield opportunities'
                            : 'Take a glance at some of our past opportunities'
                    }
                    button={
                        <ViewOtherOpportunitiesButton
                            buttonSize="extraSmall"
                            buttonType="text"
                        />
                    }
                />
                <div className="flex flex-col w-full gap-4 md:flex-row md:justify-between md:items-center">
                    <OpportunityTabs
                        triggerGaEventsForOpportunitiesTabs={
                            triggerGaEventsForOpportunitiesTabs
                        }
                        tabs={tabs}
                        regulationTabs={regulationTabs}
                        isUnlistedSDI={isUnlistedSDI}
                    />
                    {(filter === opportunitiesFilterTypes.BOND ||
                        filter === 'bond') &&
                        showActiveOpportunities && (
                            <FilterAndSort
                                showResultsScreen={showResultsScreen}
                                count={count}
                            />
                        )}
                </div>
            </section>
            {/* {descriptionText && showActiveOpportunities
                    ? (<Text content={descriptionText} className="p5-regular md:p4-regular whitespace-pre-line text-gray-500 md:mt-2 px-4 md:px-0" />)
                    : null} */}
        </AnimatePresence>
    );
};

export default OpportunityOverview;

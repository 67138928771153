import { useMemo, useRef, useState } from 'react';
import DesktopNavbar from '@components/Navbar/Desktop/Navbar';
import MobileNavbar from '@components/Navbar/Mobile/Navbar';
import useEventListener from '@hooks/useEventListner';
import {
    triggerCustomGaEvent,
} from '@helpers/miscelleanous';
import useAuthStore from '@store/useAuthStore';
import Button from '@ui/atoms/design-system/Button';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { cn } from '@ui/atoms/utils/utils';
import usePositionChatbot from '@hooks/functional/usePositionChatbot';

const LoginButton = ({ onClick, isDarkMode, className = '' }) => {
    const { width } = useWindowDimensions();
    return (
        <Button
            buttonType={isDarkMode ? 'tertiary' : 'primary'}
            onClick={onClick}
            buttonText={`Login/Sign Up`}
            buttonSize={`${width < 768 ? 'extrasmall' : 'small'}`}
            className="!rounded-full"
        />
    );
};

function NavHeader({
    hideHeaderOnScroll = false,
    translucent = false,
    homePage = false,
    isDarkMode = false,
}) {
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const lastScrollPosition = useRef(0);
    const { isLogged } = useAuthStore();
    usePositionChatbot({ isLogged })

    const onScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setIsHeaderVisible(
            lastScrollPosition.current > currentScrollPos ||
            currentScrollPos <= 0
        );

        lastScrollPosition.current = currentScrollPos;
    };

    const [lastScrollPos, setLastScrollPos] = useState(0);
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const visible =
            lastScrollPos > currentScrollPos || currentScrollPos === 0;
        setLastScrollPos(currentScrollPos);
    };

    useEventListener('scroll', handleScroll);
    hideHeaderOnScroll && useEventListener('scroll', onScroll);

    const handleHeaderItemsClick = (gtmTriggerName) => {
        if (gtmTriggerName) {
            triggerCustomGaEvent(gtmTriggerName);
        };
    };

    const darkMode = useMemo(
        () => lastScrollPos < 200 && isDarkMode,
        [lastScrollPos, isDarkMode]
    );
    return (
        <div
            id="sticky-header"
            className={cn(
                'fixed flex flex-col items-center justify-center !m-0 h-16 navbar-sticky left-0 z-100 w-full',
                {
                    'slide-up': !isHeaderVisible,
                    'bg-opacity-25': translucent,
                    'backdrop-blur-[36px] bg-[#FFFFFF1A]': darkMode,
                }
            )}
        >
            {/* Mobile and Tablet view */}
            <MobileNavbar
                LoginButton={LoginButton}
                homePage={homePage}
                handleHeaderItemsClick={handleHeaderItemsClick}
                isDarkMode={darkMode}
            />
            {/* desktop view */}
            <DesktopNavbar
                LoginButton={LoginButton}
                homePage={homePage}
                handleHeaderItemsClick={handleHeaderItemsClick}
                isDarkMode={darkMode}
            />
        </div>
    );
}

export default NavHeader;

/**
 * BottomDragSheet Component
 *
 * Props:
 * {boolean} isVisible - Controls whether the BottomSheet is closed.
 * {React.ReactNode} children - The default content to display in the BottomSheet.
 * {function} renderSmall? - Custom render function for the "small" view.
 * {function} renderMedium? - Custom render function for the "medium" view.
 * {function} renderLarge? - Custom render function for the "large" view.
 * {function} onViewChange? - Callback function triggered when the view changes. It receives the new view state as an argument.
 * {string} externalView? - Specifies the initial or externally controlled view state. Must be one of the defined BottomSheetView values (SMALL, MEDIUM, LARGE).
 * {Array<string>} availableViews? - Array of available view states. Defaults to [SMALL, MEDIUM, LARGE].
 * {object<size>} sizeVarients? - Define size for all views
 * {React.RefObject} contentRef? - Reference to the content element that triggers the BottomSheet to open.
 */

import { useState, useEffect, useRef, useMemo } from 'react';
import { motion, useAnimation, useMotionValue } from 'framer-motion';

export const BottomSheetView = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const CLOSED = 'closed';

function BottomSheet({
    isVisible,
    setIsVisible,
    children,
    renderSmall,
    renderMedium,
    renderLarge,
    onViewChange = () => {},
    availableViews = [BottomSheetView.SMALL, BottomSheetView.MEDIUM, BottomSheetView.LARGE], 
    sizeVarients = {
        [BottomSheetView.LARGE]: { y: '10%' },
        [BottomSheetView.MEDIUM]: { y: '35%' },
        [BottomSheetView.SMALL]: { y: `${((window.innerHeight - 150) / window.innerHeight) * 100}%` },
        [CLOSED]: { y: '100%' },
    },
    contentRef = null,
    outerColorClass = 'bg-basicWhite border border-gray-50'
}) {
    const views = availableViews?.length === 0 ? [BottomSheetView.SMALL, BottomSheetView.MEDIUM, BottomSheetView.LARGE] : availableViews;
    const [currentView, setCurrentView] = useState(CLOSED);
    const y = useMotionValue(window.innerHeight);
    const controls = useAnimation();

    function onSmall() {
        setCurrentView(BottomSheetView.SMALL);
        onViewChange(BottomSheetView.SMALL);
    }

    function onIntermediate() {
        if (views.includes(BottomSheetView.MEDIUM)) {
            setCurrentView(BottomSheetView.MEDIUM);
            onViewChange(BottomSheetView.MEDIUM);
        }
    }

    function onOpen() {
        setCurrentView(BottomSheetView.LARGE);
        onViewChange(BottomSheetView.LARGE);
    }

    function onClose() {
        setCurrentView(CLOSED);
        onViewChange(CLOSED);
        setIsVisible(false);
    }

    const handleTransition = (view, fallbackView = null) => {
        if (views.includes(view)) {
            switch (view) {
                case BottomSheetView.SMALL:
                    onSmall();
                    break;
                case BottomSheetView.MEDIUM:
                    onIntermediate();
                    break;
                case BottomSheetView.LARGE:
                    onOpen();
                    break;
                default:
                    onClose();
            }
            controls.start(view);
        } else if (fallbackView) {
            handleTransition(fallbackView);
        } else {
            onClose();
            controls.start(CLOSED);
        }
    };

    function onDragEnd(event, info) {
        const currentPosition = parseFloat(y.get()) || window.innerHeight;

        const thresholds = {
            SMALL: window.innerHeight * 0.7,
            MEDIUM: window.innerHeight * 0.3,
            CLOSE: window.innerHeight * 0.9,
        };

        const dragDirection = info.offset.y > 0 ? 'down' : 'up';

        if (currentPosition > thresholds.CLOSE && dragDirection === 'down') {
            onClose();
            controls.start(CLOSED);
        } else if (currentPosition > thresholds.SMALL) {
            handleTransition(BottomSheetView.SMALL, CLOSED);
        } else if (currentPosition > thresholds.MEDIUM) {
            if (dragDirection === 'up') {
                handleTransition(BottomSheetView.MEDIUM, views.includes(BottomSheetView.LARGE) ? BottomSheetView.LARGE : BottomSheetView.SMALL);
            } else {
                handleTransition(BottomSheetView.MEDIUM, BottomSheetView.SMALL);
            }
        } else {
            handleTransition(BottomSheetView.LARGE, views.includes(BottomSheetView.MEDIUM) ? BottomSheetView.MEDIUM : BottomSheetView.SMALL);
        }
    }

    const overlayVisible = currentView === BottomSheetView.LARGE || currentView === BottomSheetView.MEDIUM;

    const renderContent = useMemo(() => {
        switch (currentView) {
            case BottomSheetView.LARGE:
                return renderLarge ? renderLarge() : children;
            case BottomSheetView.MEDIUM:
                return renderMedium ? renderMedium() : children;
            case BottomSheetView.SMALL:
                return renderSmall ? renderSmall() : children;
            case CLOSED:
                return null;
        }
    }, [currentView, renderLarge, renderMedium, renderSmall, children]);

    useEffect(() => {
        if (isVisible) {
            controls.start(views?.[0] || BottomSheetView.SMALL);
            setCurrentView(views?.[0] || BottomSheetView.SMALL);
            onViewChange(views?.[0] || BottomSheetView.SMALL)
        } else {
            controls.start(CLOSED);
            setCurrentView(CLOSED);
        }
    }, [isVisible]);

    useEffect(() => {
        document.body.style.overflow = (currentView === BottomSheetView.LARGE || currentView === BottomSheetView.MEDIUM) ? 'hidden' : 'unset';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [currentView]);

    useEffect(() => {
        const handleFocusOrClick = (e) => {
            if (contentRef.current && contentRef.current.contains(e.target)) {
                if (views.includes(BottomSheetView.LARGE)) {
                    onOpen();
                    controls.start(BottomSheetView.LARGE);
                }
            }
        };

        if(contentRef){
            document.addEventListener('click', handleFocusOrClick);
            document.addEventListener('focusin', handleFocusOrClick);
    
            return () => {
                document.removeEventListener('click', handleFocusOrClick);
                document.removeEventListener('focusin', handleFocusOrClick);
            };
        }
    }, [views, contentRef]);

    return (
        <div className={overlayVisible ? `h-screen fixed z-50 w-full top-0 bg-gray-800/50` : ''}>
            <motion.div
                role="dialog"
                aria-hidden={currentView === CLOSED}
                aria-label="Bottom Sheet"
                drag="y"
                onDragEnd={onDragEnd}
                initial={CLOSED}
                animate={controls}
                transition={{
                    type: 'spring',
                    damping: 40,
                    stiffness: 400,
                }}
                variants={sizeVarients}
                dragConstraints={{ top: 0 }}
                dragElastic={0.2}
                className={`fixed bottom-0 left-0 right-0 w-full h-full ${outerColorClass} shadow-sm rounded-t-2xl z-50`}
                style={{ y }}
            >
                <div
                    className="w-full flex items-center justify-center h-4 cursor-row-resize absolute"
                    tabIndex={0}
                    role="button"
                    aria-label="Resize bottom sheet"
                >
                    <motion.div
                        className="absolute top-3 h-1 bg-gray-300 rounded-full origin-left z-50"
                        initial={{ width: '40px' }}
                        animate={{ width: '80px' }}
                        transition={{
                            duration: 0.5,
                            ease: 'easeInOut',
                        }}
                    />
                </div>
                <div className="flex-1 h-full">{renderContent}</div>
            </motion.div>
        </div>
    );
}

export default BottomSheet;
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { updateQueryParams } from '@lib/url';

import Button from '@ui/atoms/design-system/Button';
import {
    RadioGroup,
    RadioGroupContainer,
    RadioGroupItem,
} from '@ui/atoms/design-system/radio-group';
import { buttonTypes } from '@ui/atoms/utils/enums';
import { sortSchema } from '@components/Investor/Opportunities/Filters/utils/form-schema';
import { useClickAway } from '@uidotdev/usehooks';

const SortMenu = ({ onClose, sortOptions = [], defaultSort = 'default' }) => {
    const { width } = useWindowDimensions();
    const router = useRouter();
    const { query } = router;
    const modalRef = useClickAway(() => {
        onClose();
    });

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(
            sortSchema({
                bondSort: query.sort || defaultSort,
            })
        ),
        defaultValues: {
            bondSort: query.sort || defaultSort,
        },
    });

    const updateSortQuery = (value) => {
        const sortValue = value === defaultSort ? undefined : value;
        const isRatingSort = value === 'rating';
        const sortBy =
            value === defaultSort ? undefined : isRatingSort ? 'desc' : 'asc';

        const queryString = updateQueryParams(router.query, {
            sort: sortValue,
            sortBy,
        });

        router.push({ query: queryString });
    };

    const onSubmit = (data) => {
        updateSortQuery(data.bondSort);
        onClose();
    };

    const handleClear = () => {
        reset({ bondSort: defaultSort });
        updateSortQuery(defaultSort);
        onClose();
    };

    if (!sortOptions.length) return null;

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="right-0 z-50 inline-block w-full overflow-auto border-gray-200 rounded-lg md:shadow-md md:border md:absolute top-8 bg-basicWhite min-w-44"
            ref={modalRef}
        >
            <Controller
                control={control}
                name="bondSort"
                render={({ field }) => (
                    <RadioGroup className="flex flex-col flex-1 w-full gap-0 md:gap-0">
                        <RadioGroupContainer
                            {...field}
                            onChange={(value) => {
                                field.onChange(value);
                                if (width > 768) {
                                    updateSortQuery(value);
                                    onClose();
                                }
                            }}
                            className="flex-col items-start gap-0 md:gap-0"
                        >
                            {sortOptions.map((option) => (
                                <RadioGroupItem
                                    key={option.value}
                                    id={option.value}
                                    value={option.value}
                                    label={option.label}
                                    checkedLabelClass="p4-semibold text-gray-700"
                                    unCheckedLabelClass="p4-regular text-gray-700"
                                    className="gap-3 px-3 py-3.5 md:py-2 w-full"
                                    checked={field.value === option.value}
                                />
                            ))}
                        </RadioGroupContainer>
                    </RadioGroup>
                )}
            />
            <div className="sticky w-full p-4 border-t bg-basicWhite md:hidden">
                <div className="flex items-center gap-4">
                    <Button
                        buttonText="Clear"
                        buttonType={buttonTypes.TERTIARY}
                        widthClass="w-full max-w-[92px]"
                        onClick={handleClear}
                        type="button"
                    />
                    <Button
                        buttonText="Apply"
                        buttonType={buttonTypes.PRIMARY}
                        widthClass="flex-1 w-full"
                    />
                </div>
            </div>
        </form>
    );
};

export default SortMenu;

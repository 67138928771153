import { Image, Modal, Text } from '@ui/atoms';
import Button from '@ui/atoms/design-system/Button';
import React from 'react';
import FilterModal from './FilterModal';
import SortMenu from './SortMenu';
import { useRouter } from 'next/router';
import { toPascalCaseWithSpaces } from '@helpers/miscelleanous';
import { updateQueryParams } from '@lib/url';
import { cn } from '@ui/atoms/utils/utils';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getAppliedFilters } from './utils/utils';
import { useSearchParams } from 'next/navigation';
import BottomDrawer from '@ui/atoms/design-system/bottom-drawer';
import { SORT_OPTIONS } from './utils/helper';

const FilterAndSort = ({ showResultsScreen, count }) => {
    const { width } = useWindowDimensions();
    const router = useRouter();
    const { query } = router;
    const { sort, sortBy } = query;
    const validSortOptions = SORT_OPTIONS.map((option) => option.value);
    const isValidSort = validSortOptions.includes(sort);
    const [showFilter, setShowFilter] = React.useState(false);
    const [showSort, setShowSort] = React.useState(false);

    const searchParams = useSearchParams();
    const queryParams = Object.fromEntries(searchParams.entries());
    const { count: filterCount } = getAppliedFilters(queryParams);

    const handleFilterClick = () => {
        setShowSort(false);
        setShowFilter((prev) => !prev);
    };

    const onCloseFilter = () => {
        setShowFilter(false);
    };
    const handleSortClick = () => {
        setShowFilter(false);
        setShowSort((prev) => !prev);
    };

    const onCloseSort = () => {
        setShowSort(false);
    };

    const handleSortOrder = () => {
        if (isValidSort && sort) {
            const newSortOrder = sortBy === 'asc' ? 'desc' : 'asc';
            const updatedQuery = updateQueryParams(router?.query, {
                sortBy: newSortOrder,
            });
            router.push({
                query: updatedQuery,
            });
        }
    };

    return (
        <div className="relative flex flex-col gap-4">
            <div
                className={cn(
                    ' flex items-center gap-2 px-4 md:px-0 md:gap-4',
                    {
                        'md:gap-3': showResultsScreen,
                    }
                )}
            >
                <Button
                    buttonType="text"
                    buttonText="Filter"
                    prefixComponent={
                        <Image
                            src={`/icons/${
                                width < 768
                                    ? 'SliderHorizontal'
                                    : 'FunnelSimple'
                            }.svg`}
                            alt="filter"
                            className="w-4 h-4"
                            objectFit="contain"
                        />
                    }
                    suffixComponent={
                        filterCount > 0 && (
                            <div className="w-4 h-4 rounded-full bg-basicWhite md:bg-primary-70 text-primary-500 p7-medium flex-center">
                                {filterCount}
                            </div>
                        )
                    }
                    onClick={handleFilterClick}
                    className={cn(
                        'p-2 border border-gray-200 rounded-full md:rounded-none md:border-0 md:p-0',
                        {
                            'border-r-2 md:!border-r-2  px-3 md:px-3':
                                showResultsScreen,
                            '!bg-primary-50':
                                !showResultsScreen &&
                                filterCount > 0 &&
                                width < 768,
                        }
                    )}
                />
                <div className={cn('flex items-center gap-2 px-0 md:px-0 ')}>
                    <Button
                        buttonType="text"
                        buttonText={
                            isValidSort
                                ? `Sort By: ${toPascalCaseWithSpaces(
                                      SORT_OPTIONS.find(
                                          (option) => option.value === sort
                                      ).label
                                  )}`
                                : 'Sort'
                        }
                        prefixComponent={
                            <Image
                                src="/icons/ChevronDownUp.svg"
                                alt="sort"
                                className="w-4 h-4"
                                objectFit="contain"
                            />
                        }
                        onClick={handleSortClick}
                        className={cn(
                            'flex items-center gap-2 p-2 border border-gray-200 rounded-full md:border-none md:p-0 ',
                            {
                                '!bg-primary-70':
                                    !showResultsScreen && sort && width < 768,
                            }
                        )}
                    />
                    {isValidSort && (
                        <div
                            className="flex items-center gap-2 cursor-pointer"
                            onClick={handleSortOrder}
                        >
                            <Image
                                src="/icons/ArrowCircleUp.svg"
                                alt="close"
                                className={cn(
                                    'md:w-6 md:h-6 h-8 w-8 transform transition-all ease-in-out',
                                    {
                                        ' rotate-180': sortBy === 'desc',
                                    }
                                )}
                                objectFit="contain"
                            />
                        </div>
                    )}
                </div>
            </div>
            {count ? (
                <Text
                    content={`${count} active bonds found`}
                    className="px-4 text-gray-500 p5-medium md:hidden"
                />
            ) : null}
            {showFilter &&
                (width > 768 ? (
                    <Modal
                        show={showFilter}
                        onClose={onCloseFilter}
                        width="w-full max-w-[472px] m-0 md:m-0"
                        label={'Filter Bonds'}
                        labelClassName="text-primary-500 h5-semibold"
                        paddingClass="p-0"
                        roundedClass="overflow-hidden rounded-xl md:rounded-2xl"
                    >
                        <FilterModal onClose={onCloseFilter} />
                    </Modal>
                ) : (
                    <BottomDrawer
                        visible={showFilter}
                        onClose={onCloseFilter}
                        title="Filter Bonds"
                        titleClassName="text-left h5-semibold self-start mr-auto text-primary-500"
                        wrapperClass="max-h-[80%] overflow-auto"
                        textWrapperClass="z-10"
                    >
                        <FilterModal onClose={onCloseFilter} />
                    </BottomDrawer>
                ))}
            {showSort &&
                (width > 768 ? (
                    <SortMenu
                        onClose={onCloseSort}
                        sortOptions={SORT_OPTIONS}
                        defaultSort={SORT_OPTIONS[0].value}
                    />
                ) : (
                    <BottomDrawer
                        visible={showSort}
                        onClose={onCloseSort}
                        title="Sort"
                        titleClassName="text-left h5-semibold self-start mr-auto text-primary-500"
                        textWrapperClass="z-10"
                        wrapperClass="max-h-[80%] overflow-auto"
                    >
                        <SortMenu
                            onClose={onCloseSort}
                            sortOptions={SORT_OPTIONS}
                            defaultSort={SORT_OPTIONS[0].value}
                        />
                    </BottomDrawer>
                ))}
        </div>
    );
};

export default FilterAndSort;

import routes from './routes';

// (secondary level pages and baseUrl if user comes from link)
export const secondLevelPageDetails = {
    '/dashboard/investments/[investmentId]': {
        title: 'Investment Details',
        baseUrl: routes.dashboard.investments.base,
    },
    '/opportunity/[id]': {
        title: `Opportunity Details`,
        baseUrl: '/opportunities'
    },
    '/ai-safe': {
        title: `aiSafe`,
        baseUrl: '/dashboard/ai-safe'
    },
    '/blogs/[slug]': {
        title: `Blog Details`,
        baseUrl: '/blogs'
    },
    '/add-beneficiary': {
        title: 'Add Beneficiary',
        baseUrl: '/opportunities'
    }
};
import { positionChatBotWithAuth, positionChatBotWithOutAuth } from '@helpers/miscelleanous';
import { useWindowSize } from '@uidotdev/usehooks';
import { useEffect } from 'react';

const usePositionChatbot = ({ isLogged }) => {
     const { width } = useWindowSize();

    useEffect(() => {
        // MutationObserver to check when Freshchat widget appears
        const observer = new MutationObserver(() => {
            if (document.querySelector('#fc_frame')) {
                if (isLogged) {
                    setTimeout(() => {
                        positionChatBotWithAuth(width);
                    }, 500);
                } else {
                    positionChatBotWithOutAuth(width);
                }
                observer.disconnect(); // Stop observing once applied
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, [isLogged]);
};

export default usePositionChatbot;

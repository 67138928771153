import React, { forwardRef, useState, useEffect } from 'react';
import * as Slider from '@radix-ui/react-slider';

import { cn } from '@ui/atoms/utils/utils';
import { getShortNumber } from '@ui/helpers/miscelleanous';
import {
    getClosestStep,
    customMinInvestmentSteps,
} from '@ui/atoms/utils/helper';

const CustomStepSlider = forwardRef(
    (
        {
            className,
            defaultValue = 0,
            minLabel = 0,
            maxLabel,
            valueClassName,
            onChange,
            step = 1,
            value = [0, 1000], // Ensure default value is an array
            hasRange = false,
            labelFormatter = (value) => `₹${getShortNumber(value)}`,
            showNew = false,
            steps = [],
            ...props
        },
        ref
    ) => {
        const [values, setValues] = useState(value);

        useEffect(() => {
            setValues(value);
        }, [value]);

        const handleValueChange = (val) => {
            const snappedValues = val.map((step) =>
                getClosestStep(steps, step)
            );

            if (snappedValues[0] >= snappedValues[1]) {
                return; //To Prevent thumbs from crossing
            }
            setValues(snappedValues);
            onChange?.(snappedValues);
        };

        const isClose = (threshold = 30) => {
            if (values.length === 2) {
                const [val1, val2] = values;
                const diffPercent = (Math.abs(val1 - val2) / props.max) * 100;
                return diffPercent <= threshold;
            }
            return false;
        };

        const lastSecondStep =
            customMinInvestmentSteps[customMinInvestmentSteps.length - 2];
        const secondStep = customMinInvestmentSteps[1];

        return (
            <div className="flex flex-col pr-5 mt-6">
                <Slider.Root
                    className="relative flex items-center h-4 select-none touch-none"
                    value={values}
                    onValueChange={handleValueChange}
                    min={steps[0]}
                    max={steps[steps.length - 1]}
                    ref={ref}
                    step={step}
                    defaultValue={values}
                    {...props}
                >
                    {/* Track */}
                    <Slider.Track className="relative h-0.5 w-full grow overflow-hidden rounded-full bg-primary-70">
                        <Slider.Range className="absolute h-full bg-primary-400" />
                    </Slider.Track>

                    {/* Thumb */}
                    <Slider.Thumb asChild>
                        <div className="block w-4 h-4 transition-colors border rounded-full shadow border-primary-400 bg-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                            <div
                                className={cn(
                                    'absolute transform flex items-center -left-1/2  -top-6 transition-all',
                                    {
                                        '-left-0':
                                            values[0] === props.min ||
                                            values[0] === props.max * 0.8,
                                        'right-0 left-auto':
                                            values[0] === 10000 && isClose(12),
                                    },
                                    valueClassName
                                )}
                            >
                                <span className="whitespace-nowrap">
                                    {`${labelFormatter(
                                        values[0],
                                        values[0] >= lastSecondStep ||
                                            (values[0] === 0 &&
                                                values[1] >= secondStep),
                                        values[0] === 0 &&
                                            values[1] === secondStep
                                    )}`}
                                </span>
                            </div>
                        </div>
                    </Slider.Thumb>

                    <Slider.Thumb asChild>
                        <div className="block w-4 h-4 transition-colors border rounded-full shadow border-primary-400 bg-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                            <div
                                className={cn(
                                    'absolute transform flex items-center -left-1/2 -top-6 transition-all w-fit',
                                    {
                                        '-top-6 -left-4 -translate-x-1/2':
                                            values[1] >= props.max * 0.95,
                                        'left-0':
                                            values[0] >= 10000 && isClose(12),
                                    },
                                    valueClassName
                                )}
                            >
                                {values[1] > lastSecondStep &&
                                values[0] === lastSecondStep ? null : (
                                    <span className="whitespace-nowrap">
                                        {`${labelFormatter(
                                            values[1],
                                            values[1] <= secondStep
                                        )}`}
                                    </span>
                                )}
                            </div>
                        </div>
                    </Slider.Thumb>
                </Slider.Root>
            </div>
        );
    }
);

CustomStepSlider.displayName = Slider.Root.displayName;

export { CustomStepSlider };

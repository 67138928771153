import HeaderMenuItem from '@molecules/menu/HeaderMenuItem';
import { Avatar, Image, LazyImage, Link, Text, Button } from '@ui/atoms';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '@context/auth';
import { useRouter } from 'next/router';
import useNameAvatar from '@hooks/useNameAvatar';
import { getAuth } from '@services/identity.service';
import { toTitleCase, triggerCustomGaEvent } from '@helpers/miscelleanous';
import useAuthStore from '@store/useAuthStore';
import useEventListener from '@hooks/useEventListner';
import { loginSignupEventsName } from '@helpers/ga-events-name-enum';
import { cn } from '@ui/atoms/utils/utils';

const DesktopNavbar = ({
    LoginButton,
    homePage,
    handleHeaderItemsClick,
    isDarkMode,
}) => {
    const { isLogged } = useAuthStore();
    const auth = getAuth();
    const ref = useRef(null);
    const {
        loginModal,
        signUpModal,
        menuItems: menu,
        rmDetails,
        showRMDetails,
        logout
    } = useContext(AuthContext);
    const router = useRouter();
    const routePath = router.pathname;

    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const [profileClicked, setProfileClicked] = useState(false);
    const [isCopied, setIsCopied] = useState({email: false, mobile: false});
    const { avatar } = useNameAvatar(auth?.name);

    const menuRef = useRef();
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenMenuIndex(null);
            setProfileClicked(false);
        }
    };

    const copyText = (text, type) => {
        setIsCopied(prev => ({...prev,[type]: true}));
        window.navigator.clipboard.writeText(text);
        setTimeout(() => {
            setIsCopied(prev => ({...prev,[type]: false}));
        }, 2000)
     }; 

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);



    useEventListener('scroll', () => {
        setProfileClicked(false);
    });

    return (
        <div
            className={`items-center justify-between hidden w-full h-full xl:flex max-w-[1440px] mx-auto px-8  2xl:px-10`}
        >
            <div className="flex items-center justify-start">
                <div>
                    <Link href="/">
                        <LazyImage
                            src={`/${
                                isDarkMode ? 'logo-dark.svg' : 'logo.svg'
                            }`}
                            className="w-[120px] h-8 ml-0 relative"
                            alt="logo"
                        />
                    </Link>
                </div>
            </div>
            <div className="flex-1"></div>
            <div
                className="flex items-center justify-start h-full gap-10"
                ref={ref}
            >
                <div className="flex items-center h-full" ref={menuRef}>
                    <ul className="flex items-center h-full gap-6">
                        {menu
                            ?.filter((each) => each !== null)
                            ?.filter((each) => {
                                if (
                                    isLogged &&
                                    each?.showWhenLoggedIn &&
                                    (homePage || !each?.showOnHomePageOnly)
                                ) {
                                    return each;
                                } else if (
                                    !isLogged &&
                                    each?.showWhenNotLoggedIn
                                ) {
                                    return each;
                                }
                            })
                            .map((menuItem, index) => {
                                return (
                                    <li
                                        className={`h-full flex items-center ${
                                            // menuItems.submenu &&
                                            menuItem.selectOn &&
                                            menuItem.selectOn.includes(
                                                routePath
                                            ) &&
                                            'border-b-4 border-primary-500'
                                            }`}
                                        gtmtag={menuItem.gtmtag}
                                        key={index}
                                    >
                                        <HeaderMenuItem
                                            menuItem={menuItem}
                                            selected={index === openMenuIndex}
                                            isLogged={isLogged}
                                            setSelected={(val) =>
                                                setOpenMenuIndex(
                                                    val ? index : null
                                                )
                                            }
                                            handleHeaderItemsClick={handleHeaderItemsClick}
                                            isDarkMode={isDarkMode}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                {isLogged ? (
                    <>
                        <div className="flex items-center h-full">
                            <nav className="relative flex items-center -ml-2">
                                <div className="relative flex-grow-0 p-1 cursor-pointer">
                                    <div
                                        className="relative flex flex-grow-0 p-1 xl:gap-1"
                                        onClick={() => {
                                            setProfileClicked(prev => !prev);
                                        }}
                                    >
                                        {avatar ? (
                                            <Avatar
                                                icon={avatar}
                                                className="inline-block"
                                            />
                                        ) : (
                                            <div className="inline-block ">
                                                <div className="w-8 h-8 p-2 rounded-full bg-secondary-400">
                                                    <LazyImage
                                                        src="/images/user-solid.svg"
                                                        className="relative w-full h-full"
                                                        alt="user"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className={cn("flex items-center text-gray-500", {
                                            "text-basicWhite": isDarkMode
                                        })}>
                                            <span
                                                className="self-center hidden capitalize truncate align-middle p3-semibold xl:inline-block header-username"
                                                title={auth?.name}
                                            >
                                                {toTitleCase(auth?.name)}
                                            </span>
                                            <ChevronDownIcon
                                                className={`h-6 w-6 transform-all duration-100 ${profileClicked &&
                                                    'rotate-180'
                                                    }`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {profileClicked && (
                                    <div
                                        className={`absolute ${showRMDetails
                                            ? 'w-[360px]'
                                            : 'w-[183px]'
                                            }   shadow-xl text-center bg-basicWhite border border-gray-300 rounded-md right-0 top-10`}
                                    >
                                        <div className="m-4">
                                            <div
                                                className={`flex ${showRMDetails
                                                    ? 'flex-row h-12'
                                                    : 'flex-col'
                                                    } justify-around items-center  text-primary-500 `}
                                            >
                                                <button
                                                    className={`hover:bg-gray-200  ${showRMDetails
                                                        ? 'flex-1 h-full'
                                                        : 'w-full h-12'
                                                        } bg-gray-100 rounded-md font-semibold p3-semibold`}
                                                    onClick={async () => {
                                                        await router.push(
                                                            '/profile'
                                                        );
                                                        setProfileClicked(
                                                            false
                                                        );
                                                    }}
                                                    gtmtag="gtm-click-profile"
                                                    id="myProileButton"
                                                >
                                                    <Image
                                                        className={`inline h-5 ${showRMDetails
                                                            ? 'pr-5'
                                                            : 'pr-4'
                                                            }`}
                                                        src={`/images/UserProfile.svg`}
                                                    />
                                                    Profile
                                                </button>
                                                <div
                                                    className={`${showRMDetails
                                                        ? 'h-4 w-0.5 mx-3 bg-gray-300'
                                                        : 'h-0.5 w-full my-4 bg-gray-100'
                                                        } `}
                                                />
                                                <button
                                                    className={`hover:bg-gray-200  ${showRMDetails
                                                        ? 'flex-1 h-full'
                                                        : 'w-full h-12'
                                                        } rounded-md font-semibold p3-semibold`}
                                                    onClick={() =>
                                                        // TO SHOW TOAST PASS TRUE
                                                        logout(router)
                                                    }
                                                    gtmtag="gtm-click-logout-btn"
                                                    id="logoutButton"
                                                >
                                                    <Image
                                                        className={`inline h-5 ${showRMDetails
                                                            ? 'pr-5'
                                                            : 'pr-5'
                                                            }`}
                                                        src={`/images/Logout.svg`}
                                                    />
                                                    Logout
                                                </button>
                                            </div>
                                            {showRMDetails && (
                                                <>
                                                    <div className="h-0.5 mt-4 w-full bg-gray-100" />
                                                    <Text
                                                        className={`py-3 text-gray-400 p4-medium text-left`}
                                                        content="Your Relationship Manager"
                                                    />
                                                    <div className='flex flex-col gap-4'>
                                                        <div className="flex items-center justify-start">
                                                            <Image
                                                                src={`${rmDetails.profileImageLink
                                                                    ? rmDetails.profileImageLink
                                                                    : '/images/user-solid.svg'
                                                                    }`}
                                                                className={`w-12 h-12  ${!rmDetails.profileImageLink &&
                                                                    'border-2 border-gray-400 bg-gray-400'
                                                                    } rounded-full`}
                                                            />
                                                            <Text
                                                                className={`pl-4 text-gray-900 p1-semibold text-xl`}
                                                                content={
                                                                    rmDetails.name
                                                                }
                                                            />
                                                        </div>
                                                        {rmDetails?.email ?
                                                            <div className="flex items-center text-left gap-4">
                                                            <Text
                                                                className="text-gray-900 p4-semibold text-base inline"
                                                                content="Email"
                                                            />
                                                            <div className="flex items-center gap-1 cursor-pointer" onClick={() =>  copyText(rmDetails?.email, "email")}>
                                                                <Text
                                                                    className="text-primary-500 text-base inline p4-medium"
                                                                    content={
                                                                        rmDetails.email
                                                                    }
                                                                />
                                                                {isCopied.email ? (
                                                                    <CheckCircleIcon className="text-semantic-warning-base w-4 h-4" />
                                                                ) : (
                                                                    <Image
                                                                        src="/images/copy-secondary.svg"
                                                                        className="w-4 h-4"
                                                                    />
                                                                )}
                                                            </div>
                                                            </div> 
                                                            : null
                                                        }
                                                    {rmDetails?.mobile ?
                                                            <div className="flex items-center text-left gap-3">
                                                                <Text
                                                                    className={`text-gray-900 p4-semibold text-base inline`}
                                                                    content="Phone"
                                                                />
                                                                <div className="flex items-center gap-1 cursor-pointer" onClick={() =>  copyText(rmDetails?.mobile, "mobile")}>
                                                                        <Text
                                                                            className={` text-primary-500 text-base inline p4-medium`}
                                                                            content={
                                                                                rmDetails?.mobile
                                                                            }
                                                                        />
                                                                    {isCopied.mobile ? (
                                                                        <CheckCircleIcon className="text-semantic-warning-base w-4 h-4" />
                                                                    ) : (
                                                                        <Image
                                                                            src="/images/copy-secondary.svg"
                                                                            className="w-4 h-4"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                        <a
                                                            href={`https://wa.me/${rmDetails.mobile}`}
                                                            target="_blank"
                                                        >
                                                            <Button
                                                                id="chatWithRM"
                                                                design="standard"
                                                                type="primary"
                                                                size="xl"
                                                                paddingClass="p-2"
                                                                className={`w-full`}
                                                                icon="yes"
                                                                gtmTag='gtm-click-chat-with-rm'
                                                                children={
                                                                    <>
                                                                        <Image
                                                                            className="pr-2"
                                                                            src={`/images/Chat.svg`}
                                                                        />
                                                                        <Text
                                                                            content={`Chat With ${rmDetails.name.split(
                                                                                ' '
                                                                            )[0]
                                                                                }`}
                                                                            className="inline text-base font-semibold"
                                                                        />
                                                                    </>
                                                                }
                                                            />
                                                        </a>
                                                    </div>

                                                    <div className="flex justify-start mt-5 text-left ">
                                                        <Image
                                                            className="-mt-5"
                                                            src={`/images/InfoDark.svg`}
                                                        />
                                                        <Text
                                                            className={`pl-2.5 text-gray-500 text-xs`}
                                                            content={`Your relationship manager will be available on Whatsapp chat between 09:30 AM to 7:30 PM, Monday to Friday.`}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </nav>
                        </div>
                    </>
                ) : (
                    <div className="flex items-center gap-4">
                        <LoginButton
                            onClick={() => {
                                loginModal();
                                triggerCustomGaEvent(loginSignupEventsName.CLICKED_LOGIN_SIGNUP_HEADER);
                            }}
                            isDarkMode={isDarkMode}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DesktopNavbar;
